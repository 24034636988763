<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-print="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <audit-trail-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.auditTrailFieldId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ entity.entityShortName }} </td>
                <td>{{ entity.auditReference }}</td>
                <td>{{ entity.fieldName }}</td>
                <td>{{ entity.operation }}</td>
                <td class="col-message">
                  <div v-if="!isNewValue(entity.operation, entity.oldValue, entity.entityShortName, entity.fieldName)"> {{ entity.oldValue }} </div>
                </td>
                <td class="col-message">
                  <div v-if="isNewValue(entity.operation, entity.newValue, entity.entityShortName, entity.fieldName) ">
                    <a @click.prevent="edit(entity.auditTrailFieldId, $event)"
                      :href="generateUrl(entity.auditTrailFieldId)"> View XML </a>
                  </div>
                  <div v-if="!isNewValue(entity.operation, entity.newValue, entity.entityShortName, entity.fieldName)"> {{ entity.newValue }} </div>
                </td>
                <td>{{ entity.userName }}</td>
                <td>{{ $filters.formatDateTimeLocale(entity.occurredAt, $userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <div v-if="isNewValue(entity.operation, entity.newValue, entity.entityShortName, entity.fieldName)">
                    <a class="button is-primary is-small is-inverted tooltip"
                      @click.prevent="edit(entity.auditTrailFieldId, $event)"
                      :href="generateUrl(entity.auditTrailFieldId)"
                      data-tooltip="Click to view">
                      <span class="icon is-medium">
                        <i class="mdi mdi-file-eye mdi-24px" />
                      </span>
                    </a>
                  </div>
                  <div v-if="!isNewValue(entity.operation, entity.newValue, entity.entityShortName, entity.fieldName)" />
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import AuditTrailListSideMenu from './AuditTrailListSideMenu'
import AuditTrailService from './AuditTrailService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import AuditTrailValidation from './AuditTrailValidation'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import { KeyValuePairModel } from '@/classes/viewmodels'
import AuditTrailRoutes from './route-types'
import storeMixin from './storeMixin'

export default {
  name: 'AuditTrailListView',
  components: {
    AppDetailHeader,
    [AuditTrailListSideMenu.name]: AuditTrailListSideMenu,
    BulmaTable
  },
  filters: {
  },
  mixins: [DateTimeFiltersMixin, AuditTrailValidation, storeMixin],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        entityShortName: '',
        tableName: '',
        operation:'',
        userName: '',
        userId:  '',
        dateFrom: '',
        dateTo: '',
        sortColumn: Columns[7].name,
        sortOrder: Columns[7].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    isNewValue: function (operation, nValue, entityname, fieldname)
    {
      if((operation == 'INSERT' && nValue.length > 0) || (entityname == 'Log' && fieldname == 'FullMessage') || (nValue.length > 30 ))
      {
        return true
      }
    },
    generateUrl(auditTrailFieldId) {
      return `audittrail/${auditTrailFieldId}`
    },
    edit(auditTrailFieldId, event) {
      this.$router.push({
        name: AuditTrailRoutes.AuditTrailDetail.name,
        params: { auditTrailFieldId: auditTrailFieldId }
      })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'audittrail', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList(selectedFromDate, selectedToDate) {
      this.filter.entityShortName = ''
      this.filter.tableName = ''
      this.filter.operation ='',
      this.filter.userName = '',
       this.filter.userId = '',
      this.filter.dateFrom = '',
      this.filter.dateTo = '',
      this.filter.sortColumn = Columns[7].name
      this.filter.sortOrder = Columns[7].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await AuditTrailService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    convertToKeyValuePairs(parameters) {
      const keyValuePairs = Object.keys(parameters).map(function(key) {
        return new KeyValuePairModel(key, parameters[key])
      })
      return keyValuePairs
    },
    async addNew() {
      this.addStoreItem()
    }
  }
}
</script>
