import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Entiy Name', 'entityShortName', true, 'desc', 'left'),
  new TableColumn('Reference', 'auditReference', true, 'desc', 'left'),
  new TableColumn('Field Name', 'fieldName', true, 'desc', 'left'),
  new TableColumn('Operation', 'operation', true, 'desc', 'left'),
  new TableColumn('Old Value', 'oldValue', true, 'desc', 'left'),
  new TableColumn('New Value', 'newValue', true, 'desc', 'left'),
  new TableColumn('Performed By', 'userName', true, 'desc', 'left'),
  new TableColumn('Occurred At', 'occurredAt', true, 'desc', 'left')

]
export { Columns }